document.addEventListener('mapReady', function(event) {
	loadMaps();
});

function loadMaps() {
	var maps = document.querySelectorAll('.map');
	maps.forEach(function(map) {
		var lat = parseFloat(map.dataset.lat);
		var lng = parseFloat(map.dataset.lng);
		var zoom = parseFloat(map.dataset.zoom);
		initMap(map.id, lat, lng, zoom);
	});
}

function initMap(mapID, lat, lng, zoom) {
	var mapDiv = document.querySelector('#'+mapID);
	var mapWrapper = mapDiv.closest('.map__wrapper');
	var markers = mapWrapper.querySelectorAll('.map-marker');
  
  if (window.innerWidth < 700 && mapID === 'map-home') {
  	var center = {lat: lat, lng: lng + 5};
  }	else {
  	var center = {lat: lat, lng: lng};
  }

  var map = new google.maps.Map(
      mapDiv, {
				disableDefaultUI: true,
      	zoom: zoom, 
      	center: center,
      	streetViewControl: false,
      	fullscreenControl: false,
      	mapTypeControl: false,
      	scaleControl: false,
      	styles: [
				  {
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#f5f5f5"
				      }
				    ]
				  },
				  {
				    "elementType": "labels.icon",
				    "stylers": [
				      {
				        "visibility": "off"
				      }
				    ]
				  },
				  {
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#616161"
				      }
				    ]
				  },
				  {
				    "elementType": "labels.text.stroke",
				    "stylers": [
				      {
				        "color": "#f5f5f5"
				      }
				    ]
				  },
				  {
				    "featureType": "administrative.land_parcel",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#bdbdbd"
				      }
				    ]
				  },
				  {
				    "featureType": "poi",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#eeeeee"
				      }
				    ]
				  },
				  {
				    "featureType": "poi",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#757575"
				      }
				    ]
				  },
				  {
				    "featureType": "poi.park",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#e5e5e5"
				      }
				    ]
				  },
				  {
				    "featureType": "poi.park",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#9e9e9e"
				      }
				    ]
				  },
				  {
				    "featureType": "road",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#ffffff"
				      }
				    ]
				  },
				  {
				    "featureType": "road.arterial",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#757575"
				      }
				    ]
				  },
				  {
				    "featureType": "road.highway",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#dadada"
				      }
				    ]
				  },
				  {
				    "featureType": "road.highway",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#616161"
				      }
				    ]
				  },
				  {
				    "featureType": "road.local",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#9e9e9e"
				      }
				    ]
				  },
				  {
				    "featureType": "transit.line",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#e5e5e5"
				      }
				    ]
				  },
				  {
				    "featureType": "transit.station",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#eeeeee"
				      }
				    ]
				  },
				  {
				    "featureType": "water",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#c9c9c9"
				      }
				    ]
				  },
				  {
				    "featureType": "water",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#9e9e9e"
				      }
				    ]
				  }
				]
      });

  markers.forEach(function(marker) {
  	var lat = parseFloat(marker.dataset.lat);
  	var lng = parseFloat(marker.dataset.lng);
  	var url = marker.dataset.url;

  	var marker = new google.maps.Marker({
	  	position: {lat: lat, lng: lng},
	  	map: map,
	  	icon: 'map-marker.png'
	  });

  	marker.addListener('click', function() {
      window.open(url, '_blank');
    });
  });
}