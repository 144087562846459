$(function() {
  formsJS();
});

function formsJS() {


  // Flatpickr

  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d"
  });

  // Practice Areas
  if ($('.pa-hierarchy-select').length) {
    $('.pa-hierarchy-select').each(function() {  
      var parentSelection = $(this).find('option:selected');
      if (parentSelection.length && parentSelection.hasClass('current-setting')) {
        $(this).closest('form').find('.pa-parent-select').show();
      }

      $(this).change(function() {
        console.log('changed')
        if ($(this).val() === 'sub') {
          $(this).closest('form').find('.pa-parent-select').show();
        } else {
          $(this).closest('form').find('.pa-parent-select').hide();
        }
      });
    });
  }

  // Articles
  if ($('#blog-post-parent-select').length) {
    var blogSelection = $('#blog-post-parent-select option:selected');
    if (blogSelection.length && blogSelection.hasClass('current-setting')) {
      $('#blog-post-parent-select').show();
    }

    checkArticleFields($('#article-category-select'));

    $('#article-category-select').change(function() {
      checkArticleFields($(this));
    });
  }

  function checkArticleFields(categoryField) {
    if (categoryField.val() === 'blog') {
      $('#blog-post-parent-select').show();
      $('#article-author-input').show();
      $('#external-url-input').hide();
      $('#article-embed-input').hide();
    } else if (categoryField.val() === 'in-the-news') {
      $('#external-url-input').show();
      $('#blog-post-parent-select').hide();
      $('#article-author-input').hide();
      $('#article-embed-input').hide();
    } else if (categoryField.val() === 'videos') {
      $('#external-url-input').hide();
      $('#blog-post-parent-select').hide();
      $('#article-author-input').hide();
      $('#article-embed-input').show();
    } else {
      $('#external-url-input').hide();
      $('#blog-post-parent-select').hide();
      $('#article-author-input').hide();
      $('#article-embed-input').hide();
    }
  }

	if ($('.submit-validate').length) {
		validateForm();
	}

	// Tag input
	$('.tag').click(function() {
    removeTag($(this));
  });

  // Sortable lists
  if ($('.list-sortable').length) {
    sortLists();
  }


	// Check for prefilled inputs and update status
	$('.line-fieldset').each(function() {
		if ($(this).find('input').val()) {
			$(this).addClass('is-filled');
		} else if ($(this).find('select').val()) {
			$(this).addClass('is-filled');
		}
	});

	// Animate border bottom when focused
	$('.line-fieldset').find('input, textarea, select').focusin(function() {
		$(this).closest('.line-fieldset').addClass('is-focused');
	});
	$('.line-fieldset').find('input, textarea, select').focusout(function() {
		$(this).closest('.line-fieldset').removeClass('is-focused');
	});

	// Animate input, textarea label when filled
	$('.line-fieldset').find('input, textarea').on('keyup', function() {
		if ($(this).val() && $(this).val() !== '') {
			$(this).closest('.line-fieldset').addClass('is-filled');
		} else {
			$(this).closest('.line-fieldset').removeClass('is-filled');
		}
	});
	// Animate select label when filled
	$('.line-fieldset').find('select').on('change', function() {
		if ($(this).val() && $(this).val() !== '') {
			$(this).closest('.line-fieldset').addClass('is-filled');
		} else {
			$(this).closest('.line-fieldset').removeClass('is-filled');
		}
	});

	// Animate select arrow when open/closed
	$('.line-select').focusin(function() {
		$(this).closest('.line-fieldset__wrapper').find('.ss-arrow span').addClass('open');
	});
	$('.line-select').focusout(function() {
		$(this).closest('.line-fieldset__wrapper').find('.ss-arrow span').removeClass('open');
	});

	// Auto-expand textarea as typing fills space
	$(document)
    .one('focus.autoExpand', 'textarea.autoExpand', function(){
        var savedValue = this.value;
        this.value = '';
        this.baseScrollHeight = this.scrollHeight;
        this.value = savedValue;
    })
    .on('input.autoExpand', 'textarea.autoExpand', function(){
        var minRows = this.getAttribute('data-min-rows')|0, rows;
        this.rows = minRows;
        var lineHeight = parseInt($(this).css('line-height'));
        rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / lineHeight);
        this.rows = minRows + rows;
    });
}

function removeTag(tag, validator) {
  var hiddenInput = tag.data('target');
  var tagWrapper = tag.closest('.tag-input__wrapper');
  var validator = tagWrapper.find('.tag-validator');

  tag.remove();
  $(hiddenInput).remove();

  if (!tagWrapper.find('.tag').length) {
    validator.val("");
  }
}

function sortLists() {
  $('.list-sortable').each(function() {
    $(this).sortable({
      axis: 'y',
      handle: ".grip",
      update: function(event,ui) {
        $.post($(this).data('update-url'), $(this).sortable('serialize'));
      }
    });
  });

  $('.list-sortable-h').each(function() {
    $(this).sortable({
      axis: 'x',
      handle: ".grip",
      update: function(event,ui) {
        $.post($(this).data('update-url'), $(this).sortable('serialize'));
      }
    });
  });
}


function recaptchaCallback(token,form) {
  console.log(token);
  var key = form.find('.g-recaptcha').data('sitekey');
  var tokenInput = form.find('.recaptcha-token');
  tokenInput.val(token);

  if (token.length) {
    // Submit the inquiry form
    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize(),
      success: function (data) {
        console.log('Submission was successful.');
      },
      error: function (data) {
        console.log('An error occurred.');
      }
    });
  }
}

function validate(event) {
  event.preventDefault();
  var valid = true;
  var form = $(event.target.closest('form')); //Event's target is the submit button
  var key = form.find('.g-recaptcha').data('sitekey');
  var validators = form.find('.validate');
  var errorMessage = form.find('.error-message');

  validators.each(function() {
    if (!$(this).val() || $(this).val() === '') {
      $(this).addClass('error');
      valid = false;
      errorMessage.html('Please fill out required fields.');
    } else if ($(this).attr('name') === 'email' ) {
      if( !validateEmail($(this).val()) ) {
        $(this).addClass('error');
        valid = false;
        errorMessage.html('Please supply a valid email address.');
      } else {
        $(this).removeClass('error');
      }
    } else {
      $(this).removeClass('error');
    }
  })
  if (valid && form.hasClass('validate-recaptcha')) {
    grecaptcha.execute(key).then(function(token) {
      recaptchaCallback(token,form);
    });
  } else if (valid) {
  	console.log('valid');
  	form.submit();
  }
}
function validateEmail($email) {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test( $email );
}

function validateForm() {
  $('.submit-validate').click(function(e) {
  	validate(e);
  });
}

