// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("packs/main")
require("packs/animations")
require("packs/forms")
require("packs/nav")
require("packs/search")
require("packs/map")

require('easy-autocomplete')
require('easy-autocomplete/dist/easy-autocomplete.min.css')

require('flatpickr')
require('flatpickr/dist/flatpickr.css')


require('dragscroll')
$('.dragscroll').each(function() {
  setScrollTrigger($(this));
});

$('.dragscroll').scroll(function() {
  setScrollTrigger($(this));
});

// Thumbnail scroll triggers
$('.scroll-trigger').click(function() {
  var dragscroll = $(this).closest('.dragscroll__wrapper').find('.dragscroll');
  var scrollDistance = dragscroll.find('.scroll-item')[0].offsetWidth;
  
  if ($(this).data('direction') === 'right') {
    dragscroll.animate( { scrollLeft: '+=' +  scrollDistance} );
  } else {
    dragscroll.animate( { scrollLeft: '-=' +  scrollDistance} );
  }

  setTimeout(function() {
    setScrollTrigger(dragscroll);
  }, 390);
});

function setScrollTrigger(dragscroll) {
  var wrapper = dragscroll.closest('.dragscroll__wrapper');
  var scrollLeft = dragscroll.scrollLeft();
  var maxScrollLeft = dragscroll.get(0).scrollWidth - dragscroll.get(0).clientWidth;
  var triggerLeft = wrapper.find('.scroll-trigger[data-direction="left"]');
  var triggerRight = wrapper.find('.scroll-trigger[data-direction="right"]');
  
  if (scrollLeft <= 0) {
    triggerLeft.removeClass('active');
    triggerRight.addClass('active');
  } else if (scrollLeft > 0 && scrollLeft < maxScrollLeft) {
    triggerLeft.addClass('active');
    triggerRight.addClass('active');
  } else {
    triggerRight.removeClass('active');
  }
}

// DISCLAIMER //
var disclaimer = document.querySelector('.disclaimer');
if (document.cookie.indexOf('disclaimerAccepted=true') < 0) {
  disclaimer.classList.add('active');
}

var disclaimerAccept = document.querySelector('#disclaimer-accept');
disclaimerAccept.addEventListener('click', function() {
  document.cookie = 'disclaimerAccepted=true';
  disclaimer.classList.remove('active');
});

// DISCLAIMER //

//FACEBOOK SHARING//
$('.facebook-share').click(function() {
  console.log('click');
  var url = $(this).data('url');
  console.log(url)
  FB.ui({
    method: 'share',
    href: url
  }, function(response){});

  //trackEvent('social', 'share', 'facebook', 0);
});

//FACEBOOK SHARING//
window.fbAsyncInit = function() {
  FB.init({
    appId            : '679732629101259',
    autoLogAppEvents : true,
    xfbml            : true,
    version          : 'v3.1'
  });
};

(function(d, s, id){
   var js, fjs = d.getElementsByTagName(s)[0];
   if (d.getElementById(id)) {return;}
   js = d.createElement(s); js.id = id;
   js.src = "https://connect.facebook.net/en_US/sdk.js";
   fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

//LINKEDIN SHARING//
$('.linkedin-share').click(function() {
  var url = $(this).data('url');
  window.open(url,'windowName','height=500,width=500');
});



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

require("trix")
require("@rails/actiontext")