$(function() {	
	mainSearch();
	teamSearch();
	sortAutocomplete();
	tagAutocomplete();
	articleSearch();
});

function mainSearch() {
	$('.search__clear').click(function() {
		var form = $(this).parent();
		form.find('input').val('');
		$('#search-results').html('');
		$('#default-episodes').show();

		var url = window.location.href;
		if (url.indexOf('?') > -1) {
			window.location.href = url.split('?')[0];
		}
	});

	$('#header-search-icon').click(function() {
		$('.header').addClass('searching');
		$('#header-search').focus();
	});
	$('#header-close-search').click(function() {
		console.log('close')
		$('.header').removeClass('searching');
	});

	$searchMain = $('#header-search');

	var options = {
		adjustWidth: false,
		getValue: "preview",
		url: function(phrase) {
			return "/search.json?q=" + phrase;
		},
		categories: [
			{
				listLocation: "articles",
				header: "<strong>Knowledge</strong>"
			},
			{
				listLocation: "team_members",
				header: "<strong>Team</strong>"
			},
			{
				listLocation: "practice_areas",
				header: "<strong>Capabilities</strong>"
			}
		],
		list: {
			onChooseEvent: function() {
				var url = $searchMain.getSelectedItemData().url
				window.location = url;
			}
		}
	}

	$searchMain.easyAutocomplete(options);
}

function articleSearch() {
	$searchArticles = $('#article-search');

	var options = {
		adjustWidth: false,
		getValue: "preview",
		url: function(phrase) {
			return "/search.json?q=" + phrase;
		},
		categories: [
			{
				listLocation: "articles",
				header: "<strong>Knowledge</strong>"
			},
			{
				listLocation: "tags",
				header: "<strong>Topics</strong>"
			}
		],
		list: {
			onChooseEvent: function() {
				var url = $searchArticles.getSelectedItemData().url
				window.location = url;
			}
		}
	}

	$searchArticles.easyAutocomplete(options);
}

function teamSearch() {
	$searchTeam = $('#team-search');
	var form = $searchTeam.closest('form');

	var options = {
		adjustWidth: false,
		getValue: "preview",
		url: function(phrase) {
			return "/team.json?q=" + phrase;
		},
		categories: [
			{
				listLocation: "team_members",
				header: "<strong>Team</strong>"
			}
		],
		list: {
			onChooseEvent: function() {
				var url = $searchTeam.getSelectedItemData().url
				window.location = url;
			}
		}
	}

	$('.team-browser__search-submit').click(function() {
		form.submit();
	});

	$searchTeam.easyAutocomplete(options);
}


function sortAutocomplete() {
	$('.sort-input__wrapper').each(function() {
		var sortInput = $($(this).data('target'));
		var section_id = $(this).data('section-id');
		var url = $(this).data('create-url');

		var options = {
			adjustWidth: false,
			getValue: "preview",
			url: function(phrase) {
				return "/search.json?q=" + phrase;
			},
			categories: [
				{
					listLocation: "artists",
					header: "<span class='search-result__header'>Artists</span>"
				},
				{
					listLocation: "releases",
					header: "<span class='search-result__header'>Releases</span>"
				},
				{
					listLocation: "videos",
					header: "<span class='search-result__header'>Videos</span>"
				}
			],
			list: {
				onChooseEvent: function() {
					var id = sortInput.getSelectedItemData().id;
					var contentType = sortInput.getSelectedItemData().content_type;
					var postData;

					if (contentType === 'artist') {
						postData = {
							section_item: {
								section_id: section_id,
								artist_id: id
							}
						}
					} else if (contentType === 'release') {
						postData = {
							section_item: {
								section_id: section_id,
								release_id: id
							}
						}
					} else if (contentType === 'video') {
						postData = {
							section_item: {
								section_id: section_id,
								video_id: id
							}
						}
					}
					
					$.post(url, postData);
				}
			}
		}

		sortInput.easyAutocomplete(options);
	});
}

function tagAutocomplete() {
	$('.tag-input__wrapper').each(function() {
		var tagInput = $($(this).data('target'));
		var tagWrapper = tagInput.closest('.tag-input__wrapper');
		var tagList = tagWrapper.find('.tag-list');
		var tagValidation = tagWrapper.find('.tag-validator');
		var autocompleteInput = tagWrapper.find('.tag-input');
		var categories;
		var inputName;

		if ($(this).data('categories') === 'tags') {
			inputName = 'tags';
			categories = [
				{
					listLocation: "tags",
					header: "<span class='search-result__header'>Tags</span>"
				}
			]
		} else if ($(this).data('categories') === 'authors') {
			inputName = 'authors';
			categories = [
				{
					listLocation: "team_members",
					header: "<span class='search-result__header'>Team</span>"
				}
			]
		}  else if ($(this).data('categories') === 'team_members') {
			inputName = 'related_team';
			categories = [
				{
					listLocation: "team_members",
					header: "<span class='search-result__header'>Team</span>"
				}
			]
		} else if ($(this).data('categories') === 'team_leaders') {
			inputName = 'leaders';
			categories = [
				{
					listLocation: "team_members",
					header: "<span class='search-result__header'>Team</span>"
				}
			]
		} else if ($(this).data('categories') === 'practice_areas') {
			inputName = 'practice_areas';
			categories = [
				{
					listLocation: "practice_areas",
					header: "<span class='search-result__header'>Capabilities</span>"
				}
			]
		} else if ($(this).data('categories') === 'offices') {
			inputName = 'offices';
			categories = [
				{
					listLocation: "offices",
					header: "<span class='search-result__header'>Offices</span>"
				}
			]
		} else if ($(this).data('categories') === 'admitted') {
			inputName = 'admitted';
			categories = [
				{
					listLocation: "states",
					header: "<span class='search-result__header'>States</span>"
				}
			]
		} else if ($(this).data('categories') === 'schools') {
			inputName = 'schools';
			categories = [
				{
					listLocation: "schools",
					header: "<span class='search-result__header'>Schools</span>"
				}
			]
		}

		var options = {
			adjustWidth: false,
			getValue: "preview",
			url: function(phrase) {
				return "/search.json?q=" + phrase;
			},
			categories: categories,
			list: {
				onChooseEvent: function() {
					var name = tagInput.getSelectedItemData().preview;
					var id = tagInput.getSelectedItemData().id;
					if (inputName === 'tags') {
						var target = 'hidden-tag-' +id;
					} else if (inputName === 'related_team') {
						var target = 'hidden-tag-team-' +id;
					} else if (inputName === 'practice_areas') {
						var target = 'hidden-tag-pa-' +id;
					} else if (inputName === 'offices') {
						var target = 'hidden-tag-office-' +id;
					} else if (inputName === 'admitted') {
						var target = 'hidden-tag-admitted-' +id;
					} else if (inputName === 'schools') {
						var target = 'hidden-tag-school-' +id;
					}

					tagList.append("<span class='tag' data-target='#"+target+"'>"+name+" | X</span>");
					tagList.append("<input id='"+target+"' type='hidden' name='"+inputName+"[]' value='"+id+"'/>");
					tagValidation.val('valid');
					autocompleteInput.val("").focus();

					// Need to recall tag js after adding new tag;
					$('.tag').click(function() {
				    removeTag($(this));
				  });


				}
			}
		}

		tagInput.easyAutocomplete(options);

		if (tagInput.hasClass('tag-create')) {
			// Only allow new tags to be created while typing if this class is present
			tagInput.on('keydown', function(e) {
				if (e.keyCode == 13) {
					e.preventDefault();
					// Create tag on enter
					var value = $(this).val();
						
					if (value != '') {
						//var value = $(this).val().replace(',', '');
						//var cleanValue = value.replace(',', '');
						var tagId = $('.tag').length + 1;

						tagList.append("<span class='tag' data-target='#hidden-tag-new-"+tagId+"'>"+value+" | X</span>");
						tagList.append("<input id='hidden-tag-new-"+tagId+"'' type='hidden' name='"+inputName+"[]' value='"+value+"'/>");
						tagValidation.val('valid');
						autocompleteInput.val("").focus();

						// Need to recall tag js after adding new tag;
						$('.tag').click(function() {
					    removeTag($(this));
					  });
					}
				}
			});
		}
	});

	
}

function removeTag(tag, validator) {
  var hiddenInput = tag.data('target');
  var tagWrapper = tag.closest('.tag-input__wrapper');
  var validator = tagWrapper.find('.tag-validator');

  tag.remove();
  $(hiddenInput).remove();

  if (!tagWrapper.find('.tag').length) {
    validator.val("");
  }
}