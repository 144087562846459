// SCROLL EFFECTS

if ($('.banner').length) {
  if ($('.team-profile').length) {
    if (window.innerWidth < 800) {
      $('.banner').css('background-position', '80% ' + (($(window).scrollTop() / 2) + 20) + 'px');
    } else {
      $('.banner').css('background-position', '50% ' + (($(window).scrollTop() / 2) - 35) + 'px');
    }
  } else {
    $('.banner').css('background-position', 'center ' + ($(window).scrollTop() / 2) + 'px');
  }
}

$(window).scroll(function(){
  var scrollTop = $(window).scrollTop();

  // PARALLAX EFFECTS
  if ($('.parallax-layer').length) {  
    $('.parallax-layer').each(function() {
      var speed = $(this).data('paraspeed');
      var offset = $(this).offset().top - scrollTop - 3000;
      $(this).css('transform', 'translateY(' + (offset / speed) + 'px)');
    });
  }

  if ($('.banner').length) {
    if ($('.team-profile').length) {
      if (window.innerWidth < 800) {
        $('.banner').css('background-position', '80% ' + ((scrollTop / 2) + 20) + 'px');
      } else {
        $('.banner').css('background-position', '50% ' + ((scrollTop / 2) - 35) + 'px');
      }
    } else {
      $('.banner').css('background-position', 'center ' + (scrollTop / 2) + 'px');
    }
  }

  if ($('.img-block').length) {
    $('.img-block').each(function() {
      $(this).css('background-position-y', (scrollTop / 30) + "%");// .css('background-position', '10px 10px')
    });
  }

  if (scrollTop > 80) {
    $('.header').addClass('compressed');
  } else {
    $('.header').removeClass('compressed');
  }
});

$('.logo-letter').addClass('active');
$('#logo-circle').addClass('active');

$('#home-intro .btn-circle').click(function() {

  document.querySelector('#home-stats').scrollIntoView({behavior: "smooth", block: "start"});
});

$('.stat-number').each(function() {
  var $this = $(this);
  var number = 1;
  var target = parseInt($(this).data('max'));
  var interval = setInterval(function() {
    $this.html(number);
    if (number >= target) clearInterval(interval);
    number++;
  }, 10);
});
$('.stat__number').each(function() {
  var $this = $(this);
  var number = 1;
  var target = parseInt($(this).data('max'));
  var interval = setInterval(function() {
    $this.html(number);
    if (number >= target) clearInterval(interval);
    number++;
  }, 10);
});

//Check if items are in viewport
$.fn.isOnScreen = function(){
    
  var win = $(window);
  
  var viewport = {
      top : win.scrollTop(),
      left : win.scrollLeft()
  };
  viewport.right = viewport.left + win.width();
  viewport.bottom = viewport.top + win.height();
  
  var bounds = this.offset();
  bounds.right = bounds.left + this.outerWidth();
  bounds.bottom = bounds.top + this.outerHeight();
  
  return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    
};
  