

if (Modernizr.objectfit) {
  //console.log('object')
} else {
	//console.log('should run patch')
  objectFitPatch();

  $('.hide-ie').each(function() {
  	$(this).hide();
  });
  $('.show-ie').each(function() {
  	$(this).show();
  });
}

function objectFitPatch() {
	console.log('running patch')
	$('.img-fit').each(function() {
		if ($(this)[0].tagName === "IMG") {
			var src = $(this).attr('src');
			var parent = $(this).parent();
			$(this).css({
				'opacity': '0'
			});
			parent.css({
				'background-image': "url('"+src+"')",
				'background-size': 'cover',
				'background-position': 'center center'
			});
		}
	});
	$('.bg-img').each(function() {
		if ($(this)[0].tagName === "IMG") {
			var src = $(this).attr('src');
			var parent = $(this).parent();
			$(this).css({
				'opacity': '0'
			});
			parent.css({
				'background-image': "url('"+src+"')",
				'background-size': 'cover',
				'background-position': 'center center'
			});
		}
	});
}