$(function() {
	dropdowns();
  modals();
  filterDropdown();
  dropdownCard();
  dropdownCard2();
  carousel();
  bannerBG();
  subNav();
  tabbedContent();
  tabMenus();
  teamFilter();

  //LIGHTBOXES//
  const lighboxToggle = document.querySelectorAll('.lightbox-toggle');
  if (lighboxToggle.length > 0) {
    for (let i = 0; i < lighboxToggle.length; i++) {
      lighboxToggle[i].addEventListener('click', function() {
        var target = document.querySelector(lighboxToggle[i].dataset.target);
        var iframe = target.querySelector('iframe');

        if (target.classList.contains('active')) {
          target.classList.remove('active');

          if (iframe) {
            iframe.src = '';
          }
        } else {
          target.classList.add('active');
          
          if (iframe) {
            iframe.src = target.dataset.videoSrc;
          }
        }
      });
    }
  }

  /*let announcementClosed = document.cookie.replace(/(?:(?:^|.*;\s*)announcement_closed\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  console.log(announcementClosed);
  $('.announcement-close').click(function() {
    document.cookie = 'announcement_closed=true';
  });

  if (announcementClosed === 'true') {
    
  } else {
    $('#covid-announcement').addClass('active');
  }
  console.log(announcementClosed);*/
});

// Toggle nav
var toggles = document.querySelectorAll(".toggle-nav");

for (var i = toggles.length - 1; i >= 0; i--) {
  var toggle = toggles[i];
  toggleHandler(toggle);
};


var customText = document.querySelectorAll('.custom-text');
customText.forEach((text) => {
  var links = text.querySelectorAll('a');
  links.forEach((text) => {
    text.setAttribute('target', '_blank');
  });
});


function toggleHandler(toggle) {
  toggle.addEventListener( "click", function(e) {
    e.preventDefault();
    (this.classList.contains("active") === true) ? this.classList.remove("active") : this.classList.add("active");
  });
}
$('.toggle-nav').click(function() {
  $('body').toggleClass('menu-panel-open');

  $('.menu-panel').toggleClass('active');
  $('.fade-nav').toggleClass('fade-nav-on');
});

function bannerBG() {
  if ($('.banner').length) {
    var src = $('.banner').data('img');
    $('.banner').css("background-image", "url('"+src+"')");
  }
}

function tabbedContent() {
  const tabTriggers = document.querySelectorAll('.page-subnav__item');
  const params = (new URL(document.location)).searchParams;
  const navParams = params.get('nav');
  
  if (tabTriggers.length > 0){
    if (navParams) {
      const activeTrigger = document.querySelector(`.page-subnav__item[data-target="#sub-content-${navParams}"]`);
      const activeContent = document.querySelector(`#sub-content-${navParams}`);
      activeTrigger.classList.add('active');
      activeContent.classList.add('active');
    } else if (!window.location.href.includes('career')) {
      const activeTrigger = document.querySelector('.page-subnav__item');
      const activeContent = document.querySelector(`.sub-content__wrapper`);
      activeTrigger.classList.add('active');
      activeContent.classList.add('active');
    }

    tabTriggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        const target = document.querySelector(trigger.dataset.target);
        const triggers = document.querySelectorAll('.page-subnav__item');
        const subContent = document.querySelectorAll('.sub-content__wrapper');

        subContent.forEach(content => {
          content.classList.remove('active');
        });
        triggers.forEach(content => {
          content.classList.remove('active');
        });

        target.classList.add('active');
        trigger.classList.add('active');

        const newParam = trigger.dataset.target.split('#sub-content-')[1];
        window.history.pushState(newParam, newParam, window.location.pathname+'?nav='+newParam);
      })
    })
  }
}

function subNav() {
  if ($('.sub-nav').length) {
    let params = (new URL(document.location)).searchParams;
    let navParams = params.get('nav');

    $('.sub-nav__trigger').click(function() {
      var target = $(this).data('target');
      var parentWrapper = $(this).closest('.sub-nav');

      $('.sub-nav__trigger').removeClass('active');
      $('.sub-content__wrapper').removeClass('active');

      $(target).addClass('active');
      $(this).addClass('active');
      //$('.sub-nav__trigger[data-target="'+target+'"]').addClass('active');

      if ($(target).find('.tab-menu').length) {
        $(target).find('.tab-menu li').first().click();
      }

      $('#about-title').html($(this).text());
      var newParam = target.split('#sub-content-')[1];
      window.history.pushState(newParam, newParam, window.location.pathname+'?nav='+newParam);
    });

    //DROPDOWN//
    $('.sub-nav-dropdown__label').click(function() {
      $('.sub-nav-dropdown__list').toggleClass('active');
    });
    $('.sub-nav-dropdown__item').click(function() {
      $('.sub-nav-dropdown__label').find('span').html($(this).html());
      $('.sub-nav-dropdown__list').removeClass('active');
    });

    $('.sub-nav').each(function() {
      if (navParams != null) {
        $(this).find('.sub-nav__trigger[data-target="#sub-content-'+navParams).click();
      } else {
        $(this).find('.sub-nav__trigger').first().click();
      }
    });
  }
  
}

function tabMenus() {
  var tab = $('.tab-menu li').first();
  var target = $(tab.data('target'));

  $('.tab-wrapper').removeClass('active');
  $('.tab-menu li').removeClass('active');

  tab.addClass('active');
  target.addClass('active');

  $('.tab-menu li').click(function() {
    var target = $(this).data('target');
    //var parentWrapper = $(this).parent().parent();
    var tabWrapper = $('.tab-wrapper');
    var tabLi = $('.tab-menu li');

    tabWrapper.removeClass('active');
    tabLi.removeClass('active');

    $(target).addClass('active');
    $(this).addClass('active');
  });
}

function dropdownCard() {
  $('.dropdown-card__trigger').click(function() {
    var wrapper = $(this).closest('.dropdown-card');
    var dropdownContent = wrapper.find('.dropdown-card__content');
    
    if (dropdownContent.hasClass('active')) {
      dropdownContent.hide();
      dropdownContent.removeClass('active');
      wrapper.removeClass('flex-column-center');
      wrapper.addClass('flex-start-v-center');
    } else { 
      dropdownContent.show();
      dropdownContent.addClass('active');
      wrapper.removeClass('flex-start-v-center');
      wrapper.addClass('flex-column-center');
    }
  });
}

function dropdownCard2() {
  const cardHeaders = document.querySelectorAll('.dropdown-card__header[data-behavior="expand"]');

  cardHeaders.forEach(header => {
    const card = header.closest('.dropdown-card--v2');
    header.addEventListener('click', () => {
      if (card.classList.contains('active')) {
        card.classList.remove('active');
      } else {
        card.classList.add('active');
      }
    })
  })
}

let lname, title, office, practice_area, state, school, view_all;
function teamFilter() {
  const clearButton = document.querySelector('.team-filter-clear');
  const alphaButtons = document.querySelectorAll('.team-nav__alpha button');
  const viewAll = document.querySelector('.team-filter__view-all')
  const filters = document.querySelectorAll('.team-filter select')

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (params.entries()) {
    params.entries().forEach(param => {
      if (!clearButton.classList.contains('active')) {
        clearButton.classList.add('active')
      }

      if (param[0] === 'lname') {
        const activeBtn = document.querySelector(`.team-nav__alpha button[data-value=${param[1]}`)
        if (activeBtn) {
          activeBtn.classList.add('active')
        }
      } else {
        const activeSelect = document.querySelector(`.team-filter select[name=${param[0]}`)
        if (activeSelect) {
          activeSelect.classList.add('active')
          activeSelect.value = param[1]
        }
      }
    })
  }

  if (filters.length > 0) {
    clearButton.addEventListener('click', () => {
      lname = null;
      title = null;
      office = null;
      practice_area = null;
      state = null;
      school = null;
      view_all = null;

      clearButton.classList.remove('active');

      alphaButtons.forEach(btn => {
        btn.classList.remove('active')
      });

      filters.forEach(filter => {
        filter.classList.remove('active')
        filter.selectedIndex = 0;
      });

      viewAll.classList.remove('active');

      updateFilter();
      clearUrlParams();
    });

    viewAll.addEventListener('click', () => {
      lname = null;
      title = null;
      office = null;
      practice_area = null;
      state = null;
      school = null;

      if (viewAll.classList.contains('active')) {
        view_all = null;
        viewAll.classList.remove('active')
      } else {
        view_all = true;
        viewAll.classList.add('active')
      }

      alphaButtons.forEach(btn => {
        btn.classList.remove('active')
      });

      filters.forEach(filter => {
        filter.classList.remove('active')
        filter.selectedIndex = 0;
      });

      updateFilter();
      clearUrlParams();
    })

    alphaButtons.forEach(btn => {
      btn.addEventListener('click', () => {
        view_all = null;

        alphaButtons.forEach(inactiveBtn => {
          inactiveBtn.classList.remove('active')
        })

        btn.classList.add('active')
        lname = btn.dataset.value;

        updateFilter();

        clearButton.classList.add('active');
        setUrlParam('lname', lname)
      });
    })

    filters.forEach(filter => {
      filter.addEventListener('change', () => {
        view_all = null;

        if (filter.name === 'title') {
          title = filter.value;
          setUrlParam('title', title)
        } else if (filter.name === 'practice_area') {
          practice_area = filter.value;
          setUrlParam('practice_area', practice_area)
        } else if (filter.name === 'office') {
          office = filter.value;
          setUrlParam('office', office)
        } else if (filter.name === 'school') {
          school = filter.value;
          setUrlParam('school', school)
        } else if (filter.name === 'state') {
          state = filter.value;
          setUrlParam('state', state)
        }

        filter.classList.add('active');
        updateFilter();

        clearButton.classList.add('active');
      });
    })

    function updateFilter() {
      const body = {lname, title, office, practice_area, state, school, view_all};
      const url = '/team-filtered';
      const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('X-CSRF-Token', csrf);
      xhr.setRequestHeader('dataType', 'script');
      xhr.onload = function() {
        let responseObj = xhr.response
        eval(responseObj);
      }
      xhr.send(JSON.stringify(body));
    }
  }
}

function setUrlParam(key, value) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.set(key, value)
  
  url.search = params
  const newUrl = url.toString()
  history.pushState({}, null, newUrl);
}

function clearUrlParams() {
  const url = new URL(window.location.href.split('?')[0])
  history.pushState({}, null, url)
}


function filterDropdown() {
  var form = $('#filter-form');
  var inputs = form.find('input');
  
  inputs.each(function() {
    if ($(this).val() && $(this).val() != '' && $(this).attr('name') != 'authenticity_token' && $(this).attr('name') !== 'lname') {  
      var selectedOption = $('.filter-dropdown__option[data-value="'+$(this).val()+'"]')
      var activeCategory = selectedOption.closest('.filter-dropdown__content');
      var header = $(selectedOption.data('header'));

      selectedOption.addClass('active');
      activeCategory.addClass('active');

      if (selectedOption.html().length >= 15) {
        header.html(selectedOption.html().substring(0,15) + '...');
      } else {
        header.html(selectedOption.html());
      }
      header.addClass('strong');
    } else if ($(this).attr('name') === 'lname' && $(this).val() && $(this).val() != '') {
      $('.alphbet-group').find('button[data-letter="'+$(this).val()+'"]').addClass('active');
    }
  });
  

  $('.filter-dropdown__category').click(function(e) {
    e.stopImmediatePropagation();
    if ($(this).attr('id') === 'view-all' ) {
      var form = $('#filter-form');

      $('.team-browser__input').val('');

      $('.filter-dropdown__category').each(function() {
        var header = $(this).find('span');
        header.removeClass('strong');
        header.html(header.data('default'));
      });

      $('.filter-dropdown__option').removeClass('active');
      $('.alpha-filter__option').removeClass('active');
      $('.dropdown__options').removeClass('active');
      $('#team-search').val('');

      $('input[name="view_all"]').val('true');
      $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        data: form.serialize()
      });

      resetFilterURL();

      var clearBtn = document.querySelector('.filter-clear');
      clearBtn.style.display = 'block';
    } else { 
      var dropdown = $(this).closest('.filter-dropdown');
      var content = $(this).data('list');
      $('input[name="view_all"]').val('');

      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        dropdown.find('.dropdown__options').removeClass('active');
      } else {
        $('.filter-dropdown__category').removeClass('active');
        $(this).addClass('active');
        dropdown.find('.dropdown__options').addClass('active');
      }

      $('.filter-dropdown__content').hide();
      $(content).show();
    }
  });

  $(document).click(function(e) {
    e.stopImmediatePropagation();
    if ($('.filter-dropdown__category.active').length) {  
      $('.filter-dropdown__category').removeClass('active');
    }
    if ($('.dropdown__options.active').length) { 
      $('.dropdown__options').removeClass('active');
    }
    e.stopPropagation();
  })

  $('.alpha-filter__option').click(function() {
    $('.alpha-filter__option.active').not($(this)).removeClass('active');
    $(this).toggleClass('active');

    var hiddenInput = $('input[name="lname"]');
    if ($(this).hasClass('active')) {
      hiddenInput.val($(this).html());
      var clearBtn = document.querySelector('.filter-clear');
      clearBtn.style.display = 'block';
    } else {
      hiddenInput.val('');
      var clearBtn = document.querySelector('.filter-clear');
      clearBtn.style.display = 'none';
    }



    var form = $('#filter-form');
    
    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize()
    });

    updateFilterURL(form);
  });

  function updateFilterURL(form) {
    var inputs = form.find('input');
    var newURL = location.protocol + '//' + location.host + location.pathname + '?';
    inputs.each(function () {
      var lastCharacter = newURL[newURL.length - 1];
      if ($(this).val() && $(this).val() != '' && $(this).attr('name') != 'authenticity_token') {
        if (lastCharacter === '?') {
          newURL += $(this).attr('name') + '=' + $(this).val();
        } else {
          newURL += '&' + $(this).attr('name') + '=' + $(this).val();
        }
      }
    });

    window.history.replaceState(newURL,2, newURL);
  }

  function resetFilterURL() {
    var newURL = location.protocol + '//' + location.host + location.pathname;
    window.history.replaceState(newURL,2, newURL);
  }

  $('.filter-dropdown__option').click(function() {
    // Close dropdown and clear active states
    $('.dropdown__options').removeClass('active');
    var options = $(this).closest('.filter-dropdown__content');
    var optionValue = $(this).data('value');
    var filterType = $(this).data('filter');
    var header = $(this).data('header');

    // Set input
    if (filterType === 'title') {
      var hiddenInput = $('input[name="title"]');
      var headerDefault = 'TITLE';
    } else if (filterType === 'office') {
      var hiddenInput = $('input[name="office"]');
      var headerDefault = 'LOCATION';
    } else if (filterType === 'practice-area') {
      var hiddenInput = $('input[name="practice_area"]');
      var headerDefault = 'CAPABILITY';
    } else if (filterType === 'state') {
      var hiddenInput = $('input[name="state"]');
      var headerDefault = 'ADMISSIONS';
    } else if (filterType === 'school') {
      var hiddenInput = $('input[name="school"]');
      var headerDefault = 'SCHOOLS';
    }


    if ($(this).hasClass('active')) {
      options.find('.filter-dropdown__option').removeClass('active');
      $(header).removeClass('strong');
      $(header).html(headerDefault);

      hiddenInput.val('');
    } else {
      options.find('.filter-dropdown__option').removeClass('active');
      $(this).addClass('active');
      $(header).addClass('strong');
      if ($(this).html().length >= 15) {
        $(header).html($(this).html().substring(0,15) + '...');
      } else {
        $(header).html($(this).html());
      }

      hiddenInput.val(optionValue);
    }

    if ($('.filter-dropdown__option.active').length) {
      var clearBtn = document.querySelector('.filter-clear');
      clearBtn.style.display = 'block';
    } else {
      var clearBtn = document.querySelector('.filter-clear');
      clearBtn.style.display = 'none';
    }

    $('.filter-dropdown__category').removeClass('active');

    var form = $('#filter-form');
    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize()
    });

    updateFilterURL(form);
  });

  $('.filter-view').click(function() {
    var form = $('#filter-form');
    $('input[name="view"]').val($(this).data('value'));
    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize()
    });
  });

  $('.filter-clear').click(function() {
    var clearBtn = document.querySelector('.filter-clear');
    clearBtn.style.display = 'none';

    var form = $('#filter-form');
    $('.team-browser__input').val('');

    $('.filter-dropdown__category').each(function() {
      var header = $(this).find('span');
      header.removeClass('strong');
      header.html(header.data('default'));
    });

    $('.filter-dropdown__option').removeClass('active');
    $('.alpha-filter__option').removeClass('active');
    $('.dropdown__options').removeClass('active');
    $('#team-search').val('');

    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize()
    });

    resetFilterURL();
  });



}

function modals() {
  $('.modal-open').click(function() {
    var target = $(this).data('target');
    
    $('body').addClass('modal-open');
    $(target).addClass('active');
  });
  $('.modal-close').click(function() {
    $('.modal').removeClass('active');
    $('body').removeClass('modal-open');
  });
}

function dropdowns() {
  $('.dropdown__trigger').click(function() {
    var dropdown = $(this).closest('.dropdown');
    var options = dropdown.find('.dropdown__options');

    if (options.hasClass('active')) {
      options.removeClass('active');
    } else {
      $('.dropdown__options').removeClass('active');
      options.addClass('active');
      return false;
    }
  });
}

function carousel() {
  var carousel;
  var firstItem;
  var lastItem;
  var activeItem;
  var inactiveItems;
  var prevItem;
  var nextItem;
  var isMoving = false;

  $('.carousel').each(function() {
    // Define at start, don't need update
    carousel = $(this);
    
    firstItem = $(this).find('.carousel__item').first();
    lastItem = $(this).find('.carousel__item').last();
    
    // Set first active item
    firstItem.addClass('active');

    // Define initial state at page load
    if ($(this).find('.carousel__item').length > 1) {
      initCarousel(carousel);
    } else {
      carousel.find('.carousel-control').hide();
    }
  });

  // Nav pagers
  $('.carousel-control__pager').click(function() {
    carousel = $(this).parent().closest('.carousel');
    firstItem = carousel.find('.carousel__item').first();
    lastItem = carousel.find('.carousel__item').last();

    if (!isMoving) {  
      isMoving = true;
      var direction = $(this).data('direction');
      var targetItem;

      // Redefine variables each time nav is clicked
      initCarousel(carousel);

      // CLICK SPECIFIC
      if (direction === 'next') {
        targetItem = nextItem;
      } else {
        targetItem = prevItem;
      }

      // MOVE ITEMS
      activeItem.removeClass('active');
      activeItem.addClass('page-out-' +direction);
      targetItem.addClass('page-in-' +direction);

      // RESET ITEMS AND UPDATE DEINITIONS
      setTimeout(function() {
        activeItem.removeClass('page-out-' +direction);
        targetItem.removeClass('page-in-' +direction);
        targetItem.addClass('active');

        carousel.find('.carousel__item').removeClass('hide-prev');
        carousel.find('.carousel__item').removeClass('hide-next');

        // Redefine items after they're updated
        initCarousel(carousel);

        isMoving = false;
      }, 800);
    }
  });


  function initCarousel(carousel) {
    activeItem = carousel.find('.carousel__item.active');
    inactiveItems = carousel.find('.carousel__item').not('.active');

    if (activeItem.prev('.carousel__item').length) {
      prevItem = activeItem.prev('.carousel__item');
    } else {
      prevItem = lastItem;
    }
    if (activeItem.next('.carousel__item').length) {
      nextItem = activeItem.next('.carousel__item');
    } else {
      nextItem = firstItem;
    }

    prevItem.addClass('hide-prev');
    nextItem.addClass('hide-next');
  }
}